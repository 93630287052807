<template>
    <div class="container">
        <div class="wrapper">
            <!-- Create a Login Form -->
            <form v-if="!isLoggedIn" class="login-form">
                <h1 style="text-align: center">Admin Login</h1>
                <!-- Create an input for the user's email -->
                <label for="email"
                    ><h4>Email</h4>
                    <input
                        name="email"
                        type="email"
                        v-model="email"
                        placeholder="Email"
                    />
                </label>
                <!-- Create an input for the user's password -->
                <label for="password"
                    ><h4>Password</h4>
                    <input
                        type="password"
                        v-model="password"
                        placeholder="Password"
                        name="password"
                    />
                </label>
                <!-- Create a button to submit the form -->
                <button type="submit" @click.prevent="login">Login</button>
            </form>

            <div class="dashboard-header">
                <!-- Navigation that changes frame -->
                <nav v-if="isLoggedIn">
                    <button @click.prevent="frame = 'overview'">
                        Overview
                    </button>
                    <button
                        @click.prevent="
                            getOrders();
                            frame = 'orders';
                        "
                    >
                        Orders
                    </button>
                    <button
                        @click.prevent="
                            getAttendees();
                            frame = 'attendees';
                        "
                    >
                        Attendees
                    </button>
                    <button @click.prevent="frame = 'settings'">
                        Settings
                    </button>
                </nav>

                <!--Log Out Button-->
                <button v-if="isLoggedIn" @click.prevent="logout">
                    Log Out
                </button>
            </div>

            <div v-if="isLoggedIn && frame === 'overview'">
                <h1>Overview</h1>

                <div class="overview-grid">
                    <div class="overview-module">
                        <h2></h2>
                        <table class="overview-table">
                            <thead>
                                <tr>
                                    <th>Revenue Report</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tickets Sold</td>
                                    <td style="text-align: right">
                                        {{ revenue.ticketsSold }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Sales Revenue</td>
                                    <td style="text-align: right">
                                        Rs {{ revenue.totalSalesRevenue }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Bank Transaction Charges</td>
                                    <td style="text-align: right">
                                        Rs {{ revenue.bankTransactionCharges }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>eTickets Charges</td>
                                    <td style="text-align: right">
                                        Rs {{ revenue.eTicketsCharges }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Organizer Revenue</td>
                                    <td style="text-align: right">
                                        Rs {{ revenue.organizerRevenue }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="overview-module">
                        <h2></h2>
                        <table class="overview-table">
                            <thead>
                                <tr>
                                    <th>Ticket Sales by Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        ticketType, index
                                    ) in ticketSalesByType"
                                    :key="index"
                                >
                                    <td>
                                        {{ ticketType.ticketTitle }} <br />
                                        {{ ticketType.ticketAccess }}
                                    </td>
                                    <td>{{ ticketType.count }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="isLoggedIn && frame === 'orders'">
                <h1>Orders</h1>
                <!-- Add search box to search and filter below table using search method -->
                <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Search..."
                    class="search-box"
                />

                <div class="table-container">
                    <table id="orders" class="table-data">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Phone Number</th>
                                <th>Email Address</th>
                                <th>Company</th>
                                <th>Job Title</th>
                                <th>Country</th>
                                <th>Ticket Type</th>
                                <th>Order Quantity</th>
                                <th>Order ID</th>
                                <th>Amount Paid</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="order in filteredOrders"
                                :key="order.orderId"
                            >
                                <td>{{ order.date }}</td>
                                <td>{{ order.gender }}</td>
                                <td>{{ order.firstname }}</td>
                                <td>{{ order.lastname }}</td>
                                <td>{{ order.phone }}</td>
                                <td>{{ order.email }}</td>
                                <td>{{ order.company }}</td>
                                <td>{{ order.jobTitle }}</td>
                                <td>{{ order.country }}</td>
                                <td>{{ order.ticketType }}</td>
                                <td>{{ order.ticketQuantity }}</td>
                                <td>{{ order.orderId }}</td>
                                <td>{{ order.totalPrice }}</td>
                                <td>{{ order.validation }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="isLoggedIn && frame === 'attendees'">
                <h1>Attendees</h1>
                <!-- Add search box to search and filter below table using search method -->
                <input
                    type="text"
                    v-model="searchQueryAttendees"
                    placeholder="Search..."
                    class="search-box"
                />

                <div class="table-container">
                    <table id="attendees" class="table-data">
                        <thead>
                            <tr>
                                <th>Gender</th>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>Order ID</th>
                                <th>Email Address</th>
                                <th>Company</th>
                                <th>Job Title</th>
                                <th>Country</th>
                                <th>Ticket Type</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="attendee in filterAttendees"
                                :key="attendee._id"
                            >
                                <td>{{ attendee.username.gender }}</td>
                                <td>{{ attendee.username.firstname }}</td>
                                <td>{{ attendee.username.lastname }}</td>
                                <td>{{ attendee.receiptId }}</td>
                                <td>{{ attendee.email }}</td>
                                <td>{{ attendee.company }}</td>
                                <td>{{ attendee.jobTitle }}</td>
                                <td>{{ attendee.country }}</td>
                                <td>{{ attendee.ticketType }}</td>
                                <td>{{ attendee.validation }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Pagination controls -->
                <div class="pagination">
                    <button
                        @click="goToPage(currentPage - 1, 'attendees')"
                        :disabled="currentPage === 1"
                    >
                        Previous
                    </button>
                    <span>{{ currentPage }} / {{ totalPages }}</span>
                    <button
                        @click="goToPage(currentPage + 1, 'attendees')"
                        :disabled="currentPage === totalPages"
                    >
                        Next
                    </button>
                </div>
            </div>

            <div v-if="isLoggedIn && frame === 'settings'">
                <h1>Settings</h1>

                <div class="overview-module">
                    <h2 style="text-align: center">Change Account Password</h2>
                    <form
                        id="change-password-form"
                        @submit.prevent="changePassword"
                    >
                        <label for="oldPassword"
                            ><h4>Old Password</h4>
                            <input
                                type="password"
                                id="oldPassword"
                                v-model="oldPassword"
                            />
                        </label>

                        <label for="newPassword">
                            <h4>New Password</h4>
                            <input
                                type="password"
                                id="newPassword"
                                v-model="newPassword"
                            />
                        </label>

                        <label for="confirmPassword"
                            ><h4>Confirm Password</h4>
                            <input
                                type="password"
                                id="confirmPassword"
                                v-model="confirmPassword"
                            />
                        </label>

                        <button type="submit">Change Password</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "dashboard-view",
    data() {
        return {
            email: "",
            password: "",
            isLoggedIn: false,
            frame: "overview",
            revenue: {},
            orders: [],
            searchQuery: "",
            attendees: [],
            searchQueryAttendees: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            ticketSalesByType: [],
            currentPage: 1,
            itemsPerPage: 10, // Adjust this to your preferred number of items per page
            totalPages: 1,
        };
    },
    // check if the user is already logged in
    async created() {
        try {
            const response = await fetch(this.$apiUrls.user, {
                method: "GET",
                credentials: "include",
            });

            const status = await response.json();
            if (status.isLoggedIn) {
                this.isLoggedIn = true;
                this.getRevenue();
                this.getTicketSalesByType();
            } else {
                this.isLoggedIn = false;
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        // create a method to handle the logout button
        async logout() {
            try {
                const response = await fetch(this.$apiUrls.logout, {
                    method: "POST",
                    credentials: "include",
                });

                const status = await response.json();
                // if success true set isLoggedIn to false
                if (status.success) {
                    this.isLoggedIn = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        // Create a method to handle the login form submission
        async login() {
            // api post request to login
            try {
                const response = await fetch(this.$apiUrls.login, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        email: this.email,
                        password: this.password,
                    }),
                });

                const status = await response.json();
                // if success false, show error message
                if (!status.success) {
                    alert(status.message);
                } else {
                    // if success true, set isLoggedIn to true
                    this.isLoggedIn = true;
                    this.getRevenue();
                    this.getTicketSalesByType();
                }
            } catch (error) {
                alert("Wrong email or password!");
                console.log(error);
            }
        },

        // get information from revenue report
        async getRevenue() {
            try {
                const response = await fetch(this.$apiUrls.revenue, {
                    method: "GET",
                    credentials: "include",
                });

                const revenue = await response.json();
                this.revenue = revenue;
            } catch (error) {
                console.log(error);
            }
        },

        // get ticket-sales-by-type
        async getTicketSalesByType() {
            try {
                const response = await fetch(this.$apiUrls.ticketSalesByType, {
                    method: "GET",
                    credentials: "include",
                });

                const ticketSalesByType = await response.json();
                this.ticketSalesByType = ticketSalesByType;
            } catch (error) {
                console.log(error);
            }
        },

        // get orders
        async getOrders() {
            try {
                const response = await fetch(this.$apiUrls.orders, {
                    method: "GET",
                    credentials: "include",
                });

                const orders = await response.json();
                this.orders = orders;
            } catch (error) {
                console.log(error);
            }
        },

        // get attendees
        async getAttendees() {
            try {
                const response = await fetch(
                    `${this.$apiUrls.attendees}?page=${this.currentPage}&pageSize=${this.itemsPerPage}`,
                    {
                        method: "GET",
                        credentials: "include",
                    }
                );

                const { attendees, totalPages } = await response.json();
                this.attendees = attendees;
                this.totalPages = totalPages; // Update the total pages based on the response
            } catch (error) {
                console.log(error);
            }
        },

        async goToPage(page, panel) {
            // Ensure the page is within valid bounds
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;

                if (panel === "orders") {
                    await this.getOrders();
                } else if (panel === "attendees") {
                    await this.getAttendees();
                }
            }
        },

        // create a method to handle the change password form submission
        async changePassword() {
            // check if new password and confirm password are the same
            if (this.newPassword !== this.confirmPassword) {
                alert("New password and confirm password are not the same!");
                return;
            }

            try {
                const response = await fetch(this.$apiUrls.changePassword, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        oldPassword: this.oldPassword,
                        newPassword: this.newPassword,
                    }),
                });

                const status = await response.json();
                // if success false, show error message
                if (!status.success) {
                    alert(status.message);
                } else {
                    // if success true, set isLoggedIn to true
                    this.isLoggedIn = true;
                    // clear the form
                    this.oldPassword = "";
                    this.newPassword = "";
                    this.confirmPassword = "";
                }
            } catch (error) {
                alert(
                    "Wrong email or password, or password strength does not meet minimum requirements!"
                );
            }
        },
    },
    computed: {
        filteredOrders() {
            const query = this.searchQuery.toLowerCase();

            return this.orders.filter((order) => {
                return (
                    order.date.toLowerCase().includes(query) ||
                    order.gender.toLowerCase().includes(query) ||
                    order.firstname.toLowerCase().includes(query) ||
                    order.lastname.toLowerCase().includes(query) ||
                    order.phone.toLowerCase().includes(query) ||
                    order.email.toLowerCase().includes(query) ||
                    order.company.toLowerCase().includes(query) ||
                    order.jobTitle.toLowerCase().includes(query) ||
                    order.country.toLowerCase().includes(query) ||
                    order.ticketType.toLowerCase().includes(query) ||
                    order.orderId.toLowerCase().includes(query) ||
                    order.totalPrice.toString().includes(query) ||
                    order.validation.toLowerCase().includes(query)
                );
            });
        },
        filterAttendees() {
            const query = this.searchQueryAttendees.toLowerCase();

            return this.attendees.filter((attendee) => {
                return (
                    attendee.username.firstname.toLowerCase().includes(query) ||
                    attendee.username.lastname.toLowerCase().includes(query) ||
                    attendee.receiptId.toLowerCase().includes(query) ||
                    attendee.email.toLowerCase().includes(query) ||
                    attendee.company.toLowerCase().includes(query) ||
                    attendee.jobTitle.toLowerCase().includes(query) ||
                    attendee.country.toLowerCase().includes(query) ||
                    attendee.ticketType.toLowerCase().includes(query) ||
                    attendee.validation.toLowerCase().includes(query)
                );
            });
        },
    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
}
table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
}

col {
    resize: horizontal;
}

.table-data {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.table-data td,
.table-data th {
    border: 1px solid #ddd;
    padding: 8px;
}

.table-data tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table-data tr:hover {
    background-color: #ddd;
}

.table-data th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #00c6c2;
    color: white;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.search-box {
    background: #ffffff;
    border: 2px solid #00c6c2;
    border-radius: 10px;
    height: 30px;
    text-indent: 10px;
    max-width: 200px;
    width: 100%;
    margin-bottom: 20px;
}

button {
    background: #00c6c2;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
}

nav {
    display: flex;
    gap: 10px;
}

.overview-module {
    background: #ecf0f3;
    box-shadow: -10px -10px 50px rgba(255, 255, 255, 0.8),
        10px 10px 30px rgba(63, 63, 63, 0.1);
    border-radius: 50px;
    padding: 50px;
}

.overview-grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto auto;
    gap: 30px;

    margin: 50px 0;
}

.overview-table td {
    padding: 10px 0;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 25px;
}

.login-form input {
    border: 2px solid #00c6c2;
    border-radius: 10px;
    height: 30px;
    text-indent: 10px;
    margin: 0;
}

.login-form label {
    display: flex;
    flex-direction: column;
}

.login-form label > h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #333333;
    margin: 5px 10px;
}

#change-password-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 25px;
}

#change-password-form input {
    border: 2px solid #00c6c2;
    border-radius: 10px;
    height: 30px;
    text-indent: 10px;
    margin: 0;
}

#change-password-form label {
    display: flex;
    flex-direction: column;
}

#change-password-form label > h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #333333;
    margin: 5px 10px;
}
</style>
