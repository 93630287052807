import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import { API_URLS } from "./configs/configs";

const app = createApp(App);

app.use(router);

app.config.globalProperties.$apiUrls = API_URLS;

app.mount("#app");
