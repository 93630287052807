<template>
    <!-- ---------------------- Ticket Quantity Selector ----------------------- -->
    <div id="ticket-quantity-section" class="container box-drop">
        <div class="box-heading">
            <div class="circle-step"><h1>1</h1></div>
            <div class="title-texts">
                <h1>Ticket Quantity</h1>
                <h2>
                    How many "{{ ticketTitle }} / {{ ticketAccess }}" ticket you
                    would like to order?
                </h2>
            </div>
        </div>

        <div class="box-inner" id="quantity-selector">
            <div id="ticket-price">
                <h1 style="margin-bottom: 5px">{{ ticketAccess }}</h1>
                <h3>{{ ticketCurrency }} {{ price }}</h3>
            </div>

            <div id="quantity-buttons">
                <button @click="decrementQuantity" class="btn-primary">
                    -
                </button>
                <h3>{{ ticketQuantity }}</h3>
                <button @click="incrementQuantity" class="btn-primary">
                    +
                </button>
            </div>
        </div>
    </div>

    <!-- ----------------------- Purchaser Attendee Form ----------------------- -->
    <div class="attendee-details container box-drop">
        <div class="box-heading" ref="purchaser">
            <div class="circle-step"><h1>2</h1></div>
            <div class="title-texts">
                <h1>Attendee 1 - Purchaser Information</h1>
                <h2 v-if="stepNumber == -1">
                    Please note that the information below will be displayed on
                    your pass.
                </h2>
            </div>
        </div>

        <div v-if="stepNumber == -1" class="attendee-container">
            <div class="attendee-inputs">
                <!-- Title Selector -->
                <label
                    class="label-tag span-two"
                    style="display: flex; align-items: center"
                >
                    <h4 style="font-size: 18px">Title:</h4>
                    <label
                        class="title-radio-label"
                        v-for="(titleOption, index) in titleOptions"
                        :key="index"
                    >
                        <input
                            type="radio"
                            v-model="purchaser.username.gender"
                            :value="titleOption"
                            class="title-radio-input"
                        />
                        {{ titleOption }}
                    </label>
                    <h4
                        style="color: red"
                        class="input-error-text"
                        v-if="v$.purchaser.username.gender.$error"
                    >
                        *Required
                    </h4>
                </label>

                <!-- First Name -->
                <label class="label-tag">
                    <h4>
                        First Name*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.username.firstname.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.username.firstname"
                        id="first"
                        :class="{
                            'input-error':
                                v$.purchaser.username.firstname.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="First Name*"
                    />
                </label>

                <!-- Last Name -->
                <label class="label-tag">
                    <h4>
                        Last Name*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.username.lastname.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.username.lastname"
                        :class="{
                            'input-error':
                                v$.purchaser.username.lastname.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Last Name*"
                    />
                </label>

                <!-- Email -->
                <label class="label-tag span-two">
                    <h4>
                        Email*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.email.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.email"
                        :class="{ 'input-error': v$.purchaser.email.$error }"
                        class="text-input"
                        type="text"
                        placeholder="Email*"
                    />
                </label>

                <!-- Company -->
                <label class="label-tag">
                    <h4>
                        Company*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.company.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.company"
                        :class="{ 'input-error': v$.purchaser.company.$error }"
                        class="text-input"
                        type="text"
                        placeholder="Company"
                    />
                </label>

                <!-- Job Title -->
                <label class="label-tag">
                    <h4>
                        Job Title*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.jobTitle.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.jobTitle"
                        :class="{ 'input-error': v$.purchaser.jobTitle.$error }"
                        class="text-input"
                        type="text"
                        placeholder="Job Title"
                    />
                </label>

                <!-- Country -->
                <label class="label-tag">
                    <h4>Country</h4>
                    <select v-model="purchaser.country" class="select-input">
                        <option
                            v-for="(countryOption, index) in countryOptions"
                            :key="index"
                            :value="countryOption"
                        >
                            {{ countryOption }}
                        </option>
                    </select>
                </label>

                <!-- Phone Number -->
                <label class="label-tag">
                    <h4>
                        Phone Number*
                        <span
                            class="input-error-text"
                            v-if="v$.purchaser.phone.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="purchaser.phone"
                        :class="{ 'input-error': v$.purchaser.phone.$error }"
                        class="text-input"
                        type="text"
                        placeholder="Phone Number*"
                    />
                </label>
            </div>

            <div class="card-preview">
                <!-- <h1>{{ ticketTitle }}</h1> -->
                <h1>{{ ticketAccess }}</h1>
                <h2>
                    {{ purchaser.username.firstname || "Firstname" }}
                    {{ purchaser.username.lastname || "Lastname" }}
                </h2>
                <h3>{{ purchaser.jobTitle || "Job Title" }}</h3>
                <h4>{{ purchaser.company || "Company" }}</h4>

                <div class="card-preview-images">
                    <img
                        v-if="purchaser_image_url"
                        class="card-preview-img"
                        :src="purchaser_image_url"
                        alt="profile-photo"
                    />
                    <img
                        v-if="!purchaser_image_url"
                        class="card-preview-img"
                        src="../assets/images/photo-profile.png"
                        alt="profile-photo"
                    />
                    <img
                        class="card-preview-img"
                        src="../assets/images/qr-code.svg"
                        alt="qr-code"
                    />
                </div>

                <img
                    class="card-preview-logo"
                    src="../assets/images/event-images/turbine_logo.png"
                    alt="company-logo"
                />
            </div>
        </div>

        <div v-if="stepNumber == -1" class="attendee-buttons">
            <div class="left-attendee-button">
                <input
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    accept="image/*"
                    @change="handleSelectedPhoto"
                />
                <button @click="openFileInput" class="photo-button">
                    <img
                        src="../assets/images/upload-photo-ico.svg"
                        alt="upload-photo"
                    />
                    <div>
                        Upload Photo
                        <h4>(for the badge)</h4>
                    </div>
                </button>
            </div>
            <div class="right-attendee-buttons">
                <button class="next-button" @click="nextStep(-1)">Next</button>
            </div>
        </div>
    </div>

    <!-- ------------------------ Other Attendee's Form ------------------------ -->
    <AttendeesForms
        v-for="(attendeeForm, attendeeNumber) in attendeesFormsCount"
        :id="'attendee-other-' + attendeeNumber"
        :key="attendeeNumber"
        :attendeeNumber="attendeeNumber"
        :stepNumber="stepNumber"
        :ticketTitle="ticketTitle"
        :ticketAccess="ticketAccess"
        @attendee-submitted="addOtherAttendee"
        @previous-step="previousStep"
    ></AttendeesForms>

    <!----------------------------- Payment Methods ---------------------------- -->
    <div class="container box-drop">
        <div class="box-heading">
            <div class="circle-step">
                <h1>{{ ticketQuantity + 2 }}</h1>
            </div>
            <div class="title-texts">
                <h1>Payment Method</h1>
                <h2>
                    Review your order and select your payment method to
                    continue.
                </h2>
            </div>
        </div>

        <div v-if="stepNumber == ticketQuantity - 1" class="box-inner">
            <div class="payment-method-panel">
                <div id="agreement-checkbox">
                    <label id="checkbox-container">
                        <input
                            id="checkbox-input"
                            type="checkbox"
                            v-model="agreement"
                        />
                        I agree to the
                    </label>

                    <RouterLink
                        id="terms-agree-link"
                        style="margin-left: 5px"
                        :to="$router.resolve('/terms-and-conditions').href"
                    >
                        Terms and Condition and Privacy Policy
                    </RouterLink>
                </div>

                <div class="payment-method-selection">
                    <h4>Pay securely via MIPS</h4>
                    <button
                        @click="handleCardPayment()"
                        class="card-payment-btn"
                    >
                        <img src="../assets/images/card-ico.svg" alt="card" />
                        Pay Now
                    </button>
                </div>

                <div class="payment-method-seperator"></div>

                <div class="payment-review">
                    <h4>Ticket Type</h4>
                    <h5>-</h5>
                    <h3>{{ ticketTitle }}</h3>
                    <h4>Ticket Quantity</h4>
                    <h5>-</h5>
                    <h3>{{ ticketQuantity }}</h3>
                    <h4>Total</h4>
                    <h5>-</h5>
                    <h3>{{ ticketCurrency }} {{ price }}</h3>
                </div>
            </div>
        </div>

        <div v-if="stepNumber == ticketQuantity - 1" class="payment-nav-button">
            <button
                @click="previousStep(ticketQuantity - 2)"
                class="previous-button"
            >
                Previous
            </button>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import AttendeesForms from "../components/AttendeesForms.vue";
import { reactive } from "vue";

export default {
    name: "booking-view",
    components: {
        AttendeesForms,
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            ticketId: this.$route.params.id,
            ticketTitle: "",
            ticketAccess: "",
            ticketQuantity: 1,
            ticketPrice: 0,
            ticketCurrency: "Rs",
            price: 0,
            purchaser: reactive({
                username: {
                    gender: null,
                    firstname: "",
                    lastname: "",
                },
                company: "",
                jobTitle: "",
                email: "",
                phone: "",
                photo: "",
                country: "Mauritius",
            }),
            purchaser_image_url: null,
            attendeesForms: [],
            attendeesFormsCount: 0,
            stepNumber: -1,
            titleOptions: ["Mr", "Mrs", "Other"],
            countryOptions: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "Andorra",
                "Angola",
                "Antigua & Deps",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bhutan",
                "Bolivia",
                "Bosnia Herzegovina",
                "Botswana",
                "Brazil",
                "Brunei",
                "Bulgaria",
                "Burkina",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde",
                "Central African Rep",
                "Chad",
                "Chile",
                "China",
                "Colombia",
                "Comoros",
                "Congo",
                "Congo {Democratic Rep}",
                "Costa Rica",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "East Timor",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Fiji",
                "Finland",
                "France",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Greece",
                "Grenada",
                "Guatemala",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Honduras",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland {Republic}",
                "Israel",
                "Italy",
                "Ivory Coast",
                "Jamaica",
                "Japan",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Korea North",
                "Korea South",
                "Kosovo",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macedonia",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Mauritania",
                "Mauritius",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar, {Burma}",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Poland",
                "Portugal",
                "Qatar",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "St Kitts & Nevis",
                "St Lucia",
                "Saint Vincent & the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome & Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Syria",
                "Taiwan",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "Togo",
                "Tonga",
                "Trinidad & Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Vatican City",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Zambia",
                "Zimbabwe",
            ],
            agreement: false,
        };
    },
    mounted() {
        fetch(`${this.$apiUrls.tickets}/${this.ticketId}`)
            .then((response) => response.json())
            .then((data) => {
                this.ticketTitle = data.title;
                this.ticketAccess = data.access;
                this.ticketPrice = data.price.value;
                this.price = data.price.value;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    validations() {
        return {
            purchaser: {
                username: {
                    gender: { required },
                    firstname: { required },
                    lastname: { required },
                },
                company: { required },
                jobTitle: { required },
                email: { required, email },
                phone: { required },
            },
        };
    },
    methods: {
        incrementQuantity() {
            this.ticketQuantity++;
            this.price = this.ticketPrice * this.ticketQuantity;
            this.attendeesFormsCount++;
            // this.attendeesForms.push({
            //     id: this.attendeesForms.length,
            //     username: {
            //         firstname: "",
            //         lastname: "",
            //     },
            //     company: "",
            //     jobTitle: "",
            //     email: "",
            //     phone: "",
            //     photo: "",
            //     country: "mauritius",
            // });
        },
        decrementQuantity() {
            if (this.ticketQuantity != 1) {
                this.ticketQuantity--;
                this.price = this.ticketPrice * this.ticketQuantity;
                this.attendeesFormsCount--;
                // this.attendeesForms.pop();
            }
        },
        nextStep(attendeeId) {
            if (attendeeId == -1) {
                // validate purchaser object
                this.v$.purchaser.$touch();
                if (!this.v$.purchaser.$error) {
                    this.stepNumber++;
                    this.$refs.purchaser.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                    });
                }
            }
        },
        selectStep(stepId) {
            this.stepNumber = stepId;
        },
        previousStep(previousNumber) {
            this.stepNumber = previousNumber;
            this.attendeesForms.pop();
        },
        addOtherAttendee(attendeeData) {
            this.attendeesForms.push(attendeeData);

            this.stepNumber++;
        },
        async handleCardPayment() {
            this.v$.$validate();
            if (!this.v$.$error && this.agreement == true) {
                const data = {
                    ticketId: this.ticketId,
                    ticketQuantity: this.ticketQuantity,
                    purchaser: JSON.parse(JSON.stringify(this.purchaser)),
                    associates: JSON.parse(JSON.stringify(this.attendeesForms)),
                };

                try {
                    const response = await fetch(this.$apiUrls.booking, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                        body: JSON.stringify(data),
                    });

                    if (response.ok) {
                        this.$router.push("/payment");
                    } else {
                        console.error("Failed to register data.");
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        handleSelectedPhoto(event) {
            const file = event.target.files[0];
            const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
            if (!allowedTypes.includes(file.type) || file.size > 5242880) {
                alert(
                    "Please select a valid image file (JPEG, PNG, or GIF) and make sure that your photo size is less than 5mb."
                );
                this.purchaser.photo = null;
                this.purchaser_image_url = null;
                this.$refs.fileInput.value = "";
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const mediaType = file.type;
                const base64Data = reader.result.split(",")[1];
                const base64Image = `data:${mediaType};base64,${base64Data}`;
                this.purchaser.photo = base64Image;
                this.purchaser_image_url = URL.createObjectURL(file);
            };
        },
    },
};
</script>
