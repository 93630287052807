const ROOT_URL =
    process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_PRODUCTION_URL
        : process.env.VUE_APP_LOCAL_URL;

export const API_URLS = {
    tickets: `${ROOT_URL}/api/tickets`,
    booking: `${ROOT_URL}/api/booking`,
    payment: `${ROOT_URL}/api/payment`,
    confirmation: `${ROOT_URL}/api/confirmation`,
    login: `${ROOT_URL}/api/admin/login`,
    user: `${ROOT_URL}/api/admin/is-logged-in`,
    logout: `${ROOT_URL}/api/admin/logout`,
    revenue: `${ROOT_URL}/api/admin/revenue-report`,
    orders: `${ROOT_URL}/api/admin/orders`,
    attendees: `${ROOT_URL}/api/admin/attendees`,
    changePassword: `${ROOT_URL}/api/admin/change-password`,
    ticketSalesByType: `${ROOT_URL}/api/admin/ticket-sales-by-type`,
};
