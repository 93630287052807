<template>
    <iframe
        v-if="paymentZone"
        style="width: 100%; height: 100vh; border: none"
        v-bind:src="paymentZone"
    ></iframe>
    <div v-else id="loading-payment">
        <img src="../assets/images/E-tickets-Logo.png" alt="" />
        <h1 style="margin: 0; padding: 0">Loading Payment</h1>

        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "payment-portal-view",
    data() {
        return {
            paymentZone: null,
        };
    },
    mounted() {
        fetch(this.$apiUrls.payment, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((response) => response.json())
            .then((data) => {
                this.paymentZone = data.payment_zone;
            })
            .catch((error) => {
                console.error(error);
            });
    },
};
</script>

<style>
#loading-payment {
    display: grid;
    justify-items: center;
    margin-top: 50px;
}

#loading-payment > img {
    max-width: 200px;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: linear-gradient(
        90deg,
        #00c6c2 26.04%,
        #41aa54 63.54%,
        #349980 100%
    );
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>
