<template>
    <div id="banner" class="container">
        <img
            src="../assets/images/event-images/inspiretalk_banner.png"
            alt="banner-desktop"
            class="banner-desktop"
        />
        <img
            src="../assets/images/event-images/inspiretalk_banner.png"
            alt="banner-mobile"
            class="banner-mobile"
        />
    </div>

    <!-- <div id="sponsor" class="container">
        <img
            src="../assets/images/event-images/BNI-mauritius.png"
            alt="bni-mauritius"
        />
        <img
            src="../assets/images/event-images/turbine_logo.png"
            alt="turbine"
        />
        <img
            src="../assets/images/event-images/fundkiss.png"
            alt="fundkiss"
        />
    </div> -->

    <div class="get-ticket-title container">
        <h1>INSPIRE TALK</h1>
    </div>
    
    <div id="tickets" class="container">
        <RouterLink
            :to="`/booking/${ticket._id}`"
            class="ticket"
            v-for="(ticket, index) in tickets"
            :key="ticket._id"
            style="grid-column-start: 2"
        >
            <div class="circle-color-back">
                <div
                    class="circle-dot"
                    :style="{
                        backgroundColor:
                            ticketColors[index % ticketColors.length],
                    }"
                ></div>
            </div>
            <div class="ticket-info">
                <div
                    class="ticket-title"
                    :style="{
                        backgroundColor:
                            ticketColors[index % ticketColors.length],
                    }"
                >
                    <h4>{{ ticket.title }}</h4>
                    <h5>{{ ticket.access }}</h5>
                </div>
                <h6>{{ ticketCurrency }} {{ ticket.price.value }}</h6>
                <button class="book-button">Book Now</button>
                <div class="ticket-description">
                    <p
                        v-for="(description, index) in getDescription(
                            ticket.description
                        )"
                        :key="description"
                        :class="{
                            'font-weight-bold': index === 0,
                            'font-normal': index !== 0,
                        }"
                    >
                        {{ description }}
                    </p>
                </div>
            </div>
        </RouterLink>
    </div>

    <div class="get-ticket-title container">
        <p>
            Dive into the journey of success with InspireTalk!
            <br/><br/>Join us for an enlightening event hosted by Turbine Incubator Limited, featuring  pioneering entrepreneurs such as Cedric A. Cunsamy who was previously Incubated at Turbine and is now Founder and CEO of The Party App & E-tickets.io, Nathalie Job co-CEO of Analysis an affiliate of Kantar &  an experienced professional with over 25 years of experience in Market research, data analytics and strategic consulting, and Harold Mayer, Chairman at Horizon Group and Former CEO at Ciel Textile. 
 
            <br/><br/>Discover their compelling stories of triumph over challenges in both professional and personal spheres, they will also discuss about all the positive outcomes that they gained out of those challenges. Gain invaluable insights and inspiration that will fuel your own path to success.  
            
            <br/><br/>Secure your tickets now for a session of motivation and empowerment, which will be concluded with an opportunity to expand your network and to discuss with those entrepreneurs! 

        </p>
    </div>

    <br /><br /><br />

    <div id="map">
        <iframe id="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.7887384568285!2d57.5354841!3d-20.226098099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5a13a50a7057%3A0xae6a1e095d5089e8!2sTurbine%20Business%20Incubator!5e0!3m2!1sen!2smu!4v1698737003324!5m2!1sen!2smu" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <!-- <div class="get-ticket-title" style="margin-bottom: 200px">
        <h1 style="margin: 0; padding: 0; margin-top: 100px !important">
            Speakers
        </h1>
        <h4 style="margin: 0; padding: 0; margin-bottom: 25px">
            Exciting speakers to follow at the event
        </h4>

        <div
            id="speakers-profile"
            style="
                margin-top: 60px;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                grid-gap: 50px;
                justify-items: center;
                align-content: center;
            "
        >
            <img
                src="../assets/images/event-images/admassu.png"
                alt="admassu"
            />
            <img
                src="../assets/images/event-images/anthony.png"
                alt="anthony"
            />
            <img src="../assets/images/event-images/ken.png" alt="ken" />
            <img src="../assets/images/event-images/tania.png" alt="tania" />
            <img src="../assets/images/event-images/thomas.png" alt="thomas" />
            <img src="../assets/images/event-images/vinay.png" alt="vinay" />
        </div>
    </div> -->

    <!-- <div class="organizer-terms" style="margin-top: 70px">
        <p>
            **Note
            <a href="/" target="_blank">Terms and Conditions</a>
        </p>
    </div> -->
</template>

<script>
export default {
    name: "home-view",
    data() {
        return {
            ticketColors: ["#ed796c"],
            tickets: [],
            ticketCurrency: "Rs",
        };
    },
    mounted() {
        fetch(this.$apiUrls.tickets)
            .then((res) => res.json())
            .then((data) => {
                this.tickets = data.filter((ticket) => {
                    const today = new Date();
                    const startSale = new Date(ticket.startSale);
                    const endSale = new Date(ticket.endSale);
                    return today >= startSale && today <= endSale;
                });
            })
            .catch((error) => console.log(error.message));
    },
    methods: {
        getDescription(description) {
            return description.split(",").map((item) => item.trim());
        },
    },
};
</script>

<style>

#map {
    max-width: 80vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: 40px;
    width: fit-content;
    border-style: solid;
    border-color: #E1A049;
    border-radius: 3px;
}

#sponsor {
    box-shadow: -10px -10px 50px rgba(255, 255, 255, 0.8),
        10px 10px 30px rgba(63, 63, 63, 0.1);
    border-radius: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 150px;
}

#sponsor img {
    margin: 50px 0;
    width: 20%;
}

#banner img {
    width: 100%;
    border-radius: 50px;
    margin-bottom: 120px;
}

.banner-desktop {
    display: block;
}

.banner-mobile {
    display: none;
}

@media only screen and (max-width: 1000px) and (min-width: 320px) {

    #gmap {
        width: 80vw;
    }

    #sponsor {
        flex-direction: column;
    }

    .banner-mobile {
        display: block;
    }

    .banner-desktop {
        display: none;
    }

    #sponsor img {
        margin: 50px 0;
        width: 60%;
    }
}
</style>
