<template>
    <div id="terms-container" class="container">
        <div style="display: grid; justify-items: center; text-align: center">
            <img
                src="../assets/images/E-tickets-Logo.png"
                alt="E-tickets-Logo"
                style="max-width: 200px"
            />
            <h2>
                Conditions Générale de Ventes <br />
                E-TIX LTD - e-ticket.io
            </h2>
        </div>

        <h2>Définitions</h2>
        <p>
            <u>Le client</u> - désigne toute personne souhaitant passer une
            commande sur un site <u>e-tickets.io</u> ou qui a déjà acheté un
            billet à l'entrée d'un évènement géré par <u>e-tickets.io</u>.
        </p>

        <p>
            <u>L'organisateur ou le producteur d'événement(s)</u> - désignent
            l'individu, l'association ou la société qui utilisent les
            plateformes <u>e-tickets.io</u> pour vendre ses billets ou ses
            produits. <u>e-tickets.io</u> est une plateforme de vente de ticket
            électronique mises à disposition des organisateurs.
        </p>

        <p>
            L'organisateur communique à la l'équipe de <u>e-tickets.io</u> le ou
            les responsables: la/les personne(s) détentrice des codes d'accès
            confidentiels et qui se connecte au Dashboard, à l'application
            mobile pro <u>e-tickets.io</u> access ou le fichier drive e-
            tickets.io dashboard.
        </p>

        <p>
            <u>e-tickets.io</u> access est l'application mobile professionnelle
            <u>e-tickets.io</u> qui permet à l'organisateur de contrôler ses
            billets lors d'un événement. Le site internet
            <u>e-tickets.io</u> est la plateforme destinée au public et
            permettant de découvrir et d'acheter des billets pour des
            événements.
        </p>

        <h2>Fonctionnement <u>e-tickets.io</u></h2>

        <p>
            <u>e-tickets.io</u> est une solution de billetterie qui permets aux
            organisateurs de vendre leurs billets aux clients avec une
            expérience de billetterie unique.
        </p>
        <p>
            <u>e-tickets.io</u> est un intermédiaire de vente. La responsabilité
            de <u>e-tickets.io</u> se limite à la réalisation technique de la
            vente des billets. La responsabilité des ventes, de ce à quoi elles
            donnent droit, de leur conformité aux lois et à la réglementation et
            de leurs conséquences demeurent la responsabilité de(s)
            organisateur(s).
        </p>

        <h2>1. Utilisation de <u>e-tickets.io</u> par les clients</h2>
        <h3>1.1 Achat et réception du billet</h3>

        <p>
            Un client peut acheter les billets <u>e-tickets.io</u> sur un nom de
            domaine ou sous domaine <u>e-tickets.io</u>. Contrairement à la
            réglementation en vigueur, tout achat est ferme et définitif et ne
            donne pas lieu à un délai de rétraction.
            <u>e-tickets.io</u> maintiens une politique stricte d'aucun
            remboursement.
        </p>

        <p>
            En cas d'annulation de l'évènement, voir section 2.5. En cas de
            report de l'évènement, voir section 2.5.
        </p>

        <p>
            Le client doit choisir son billet et ses quantités, fournir les
            informations nécessaires pour la commande et choisir son mode de
            paiement. Puis, le client devra renseigner ses informations
            bancaires (carte bancaires, services bancaires mobiles où autre
            scanner pour payer) avant de passer par le contrôle 3D sécure de sa
            banque afin d'effectuer un paiement sécurisé. Après confirmation
            d'un paiement réussi, le client recevra son billet par e-mail et
            pourra le télécharger directement sous format QR code.
        </p>

        <p>
            En cas de commande groupée, le propriétaire du billet est l'acheteur
            initial et peut transmettre ses billets aux personnes de son choix.
            Dépendant des conditions préalables imposées par l'organisateur, les
            billets <u>e-tickets.io</u> sont personnels mais non nominatifs.
        </p>

        <h3>1.2 Propriété du billet</h3>
        <p>
            Dupliqué un billet ou le transmettre à une personne illégitime est
            strictement interdit. À ce titre, la première qui se présentera avec
            le billet au contrôle d'accès sera reconnue comme le propriétaire
            légitime du billet.
        </p>

        <h3>1.3 Revente du/des billet(s)</h3>
        <p>
            La revente d'un ou de plusieurs billets est possible par l'acheteur
            en directe. Il relève de la responsabilité de ce dernier néanmoins
            d'informer l'équipe de e- tickets.io du changement.
        </p>

        <p>
            Au moment où la fonction de revente intégrée est applicable sur le
            site de e- tickets.io, le client est informé du tarif appliqué à la
            revente voulu par le détenteur. Celui-ci peut accepter le tarif et
            poursuivre sa revente ou refuser et annuler sa vente.
        </p>

        <h3>1.4 Fraude</h3>
        <p>
            La revente illégale, le duplicata, la communication de fausses
            informations, l'utilisation de moyens de paiement frauduleux sont
            strictement interdit et peuvent entraîner la suppression du billet.
        </p>

        <p>
            En cas de fraude, de tout comportement pouvant inciter, de manière
            directe ou indirecte, à la haine ou à la violence, un client peut se
            voir définitivement supprimé son billet et refuser l'accès aux
            évènements de(s) organisateurs(s).
        </p>

        <h3>1.5 Contrôle d'accès</h3>
        <p>
            Chaque billet donne droit à un évènement unique dont les horaires de
            validités sont mentionnés sur le billet et sur la page
            <u>e-tickets.io</u>
            de l'évènement où les billets ont été achetés.
        </p>

        <p>
            L'entrée dans un évènement est soumise à un contrôle du billet, qui
            donne accès à une entrée unique, sauf si indiqué dans l'évènement.
        </p>

        <p>
            <u>Un billet contenant une entrée</u> <br />
            Ce billet peut être présenté qu'une seule fois au contrôle, et la
            première personne se présentant avec le billet sera présumée être le
            propriétaire légitime du billet.
        </p>

        <p>
            <u>Un billet contenant plusieurs entrées</u> <br />
            Ce billet peut être présenté plusieurs fois au contrôle, mais ne
            sera plus valide une fois le nombre que le contenu du billet aura
            été validé
        </p>

        <p>
            L'achat d'un billet pour évènement entraine l'adhésion à son propre
            règlement. Une pièce d'identité peut être demandée lors du contrôle
            d'accès, il est donc obligatoire de l'avoir avec soi.
        </p>

        <h3>1.6 Données personnelles recueillies par <u>e-tickets.io</u></h3>

        <p>
            Les données personnelles que le client fournit sur la plateforme
            permet la réalisation de la transaction et du paiement, de
            transmettre les informations relatives à l'évènement, de permettre à
            l'organisateur de mieux connaitre son public, notamment sur leurs
            préférences artistiques et leurs préférences et fréquences de
            sorties.
        </p>

        <p>
            Ces informations permettent également de transmettre les billets
            commandés et aux organisateurs d'envoyer des informations pratiques
            relatives à la tenue de l'évènement et des prochaines en vue.
        </p>

        <p>
            Les coordonnées bancaires fournit au moment de l'achat du billet
            n'est pas sauvegardées sur les plateformes <u>e-tickets.io</u>. Les
            détails passent par un fournisseur de services, MIPSIT Digital Ltd,
            qui facilite les intégrations techniques entre
            <u>e-tickets.io</u> et les fournisseurs de modes de paiement. Les
            coordonnées bancaires fournit sont sécurisées et cryptées par MIPS,
            rendant lesdites données inaccessibles par <u>e-tickets.io</u> et
            l'organisateur. En outre, dépendant des conditions préalables de
            l'organisateurs de l'évènement des données complémentaires sont
            récupérées (mais ne se limite pas à) pour le bon fonctionnement du
            service :
        </p>

        <p>
            Nom, Prénom, Numéro de téléphone, adresse, Géolocalisation au moment
            du passage d'une commande, âge, sexe, toute préférences de
            consommation manifestée sur le site <u>e-tickets.io</u>
        </p>

        <p>
            Le client est automatiquement rajouté à la liste d'emailing de
            <u>e-tickets.io</u>. Le client recevra donc nos offres spéciales,
            annoncent d'évènement et informations importantes relatifs à tous
            les évènements. Néanmoins, le client peut se désabonner à n'importe
            quel moment.
        </p>

        <h3>1.7 Facturation du Client</h3>
        <p>
            <u>e-tickets.io</u> peut facturer le client avec un frais
            supplémentaire, qui peut ou s'ajouter au prix du billet ou être
            inclus dans le prix, pour certains évènements ou certains types de
            tarifs.
        </p>

        <h2>
            2. Utilisation de <u>e-tickets.io</u> par organisateurs d'évènements
        </h2>
        <h3>2.1 Primauté du Contrat</h3>
        <p>
            Ces conditions générales de ventes sont expressément adoptées par
            tout organisateur d'évènements qui vends des billets sur
            <u>e-tickets.io</u>. Néanmoins, si des dispositions d'un contrat
            signé entre un organisateur d'évènement et un représentant de
            <u>e-tickets.io</u>
            diffère à celles des présentes conditions générales de ventes, le
            contrat aura la primauté.
        </p>
        <h3>2.2 Fonctionnement du Dashboard</h3>
        <p>
            Le Dashboard est l'espace professionnel <u>e-tickets.io</u> qui
            permet à l'organisateur d'administrer son événement en ligne. À ce
            titre, le dashboard permets à l'organisateur de suivre la vente de
            ses billets, accéder à leurs informations clients, voir les
            commandes reçues, les invitations et la recette de son évènement.
        </p>
        <h3>2.3 Facturation</h3>
        <p>
            <u>e-tickets.io</u> facture ses services auprès des organisateurs
            d'évènements et auprès du public (si imposée) comme suit : un frais
            de billetterie agrée avec l'organisateur d'évènement, prélevé sur
            chaque achat de billet au moment de la transaction. Le frais compose
            d'une partie variable et fixe (frais de listings), plus si
            nécessaire, un minimum fixe par transaction.
        </p>

        <p><u>Partie variable</u></p>
        <p>
            La partie variable (% sur chaque transaction) est déterminé par
            l'offre que l'organisateur souscrits.
        </p>

        <p><u>Partie fixe</u></p>
        <p>
            La partie fixe représente le frais de listings (frais pour utiliser
            la plateforme e- tickets.io), le frais pour les ad (annonces) à
            afficher sur le site & autres services offerts par
            <u>e-tickets.io</u>.
        </p>

        <p>
            Cette partie est étant aussitôt consommé au moment du paiement, elle
            est <b>strictement non-remboursable.</b>
        </p>

        <p><u>Minimum fixe par transaction</u></p>
        <p>
            <u>e-tickets.io</u> se réserve le droit d'imposer un minimum fixe
            par transaction dépendant du modèle d'évènement à l'organisateur.
        </p>

        <p>
            Toutes les informations relatives à la facturation sont accessibles
            du Dashboard de l'organisateur de l'évènement et est envoyé au(x)
            responsable(s) par mail à la fin de chaque évènement.
        </p>

        <p>
            Ces données font foi en tout état de cause. De même que toutes les
            informations de performances de vente et de visibilité de la
            plateformes <u>e-tickets.io</u>.
        </p>

        <h3>2.4 Propriété & Responsabilité du compte Admin</h3>
        <p>
            La responsabilité individuelle et professionnelle du compte ayant
            accès au Dashboard incombe au responsable du compte. Le responsable
            incombe toute la responsabilité à partir du moment où il accède au
            Dashboard avec le lien ou les identifiants confidentiels confiés par
            e <u>e-tickets.io</u> par mail ou autre support de communications.
        </p>

        <p>
            En cas de conflit sur l'identité du responsable, la personne ayant
            confié sa pièce d'identité et ses infos personnelles sera considéré
            comme responsable légale du Dashboard.
        </p>

        <h3>2.5 Responsabilité de l'évènement</h3>
        <p>
            L'évènement est l'entière responsabilité de l'organisateur. ;
            notamment la conformité à la règlementation et aux lois en cours,
            aux octrois de permis, la légalité de l'évènement, la sécurité des
            clients pendant l'évènement, la politique d'accueil (si géré hors de
            l'équipe de <u>e-tickets.io</u>) et la procédure de remboursement en
            cas d'annulation ou de report.
        </p>

        <p>
            En tant qu'intermédiaire de ventes, <u>e-tickets.io</u> ne pourrait
            être tenu responsable de l'une de ces situations.
        </p>

        <p>
            En cas d'annulation de l'évènement, le client doit être remboursé à
            100% et l'organisateur se doit de régler la facture due à
            <u>e-tickets.io</u> (listage + frais de billetterie + autres si
            encourus).
        </p>

        <p>
            En cas de report, le choix sera donné au(x) client(s) de maintenir
            son/ses billet(s) pour la prochaine date, pour un autre évènement ou
            d'être remboursé.
        </p>

        <h3>2.6 Évènement illégal ou dangereux</h3>
        <p>
            En cas d'un évènement considéré comme illégal, dangereux, incitant
            de manière directe ou indirecte à la haine ou la violence,
            <u>e-tickets.io</u> se réserve le droit de couper les ventes de cet
            évènement sans que ce ne soit une obligation.
        </p>

        <h3>2.7 Décharge de responsabilité</h3>
        <p>
            L'organisateur déclare avoir pris connaissance des conditions de la
            décharge de responsabilité suivant. Il déclare et garantit à
            <u>e-tickets.io</u> que la présente décharge de responsabilité, une
            fois accepté, constituera des obligations légales valables et
            contraignantes pour lui.
        </p>

        <h3>2.8 Pouvoirs</h3>
        <p>
            Le signataire, organisateur ou représentant de l'organisateur,
            déclare et garantit à <u>e-tickets.io</u> qu'il dispose de tous les
            droits, pouvoirs et autorisations d'entreprise nécessaires pour
            signer la présente décharge de responsabilité et exécuter les
            obligations en résultant.
        </p>

        <h3>2.9 Définitions</h3>
        <p>
            Les termes suivants (singulier ou pluriel) auront le sens défini
            ci-après, à moins qu'il en soit spécifié autrement ou que le
            contexte ne permette pas de leur conférer une telle signification :
        </p>

        <p>
            <u>Client</u> : désigne toute personne effectuant une commande sur
            le site <u>e-tickets.io</u> ou ayant déjà acheté un billet pour un
            évènement géré par <u>e-tickets.io</u>
        </p>

        <p>
            <u>Organisateur</u> : désigne la personne physique, l'association,
            ou la société qui utilise la plateforme de services de l<u
                >e-tickets.io</u
            >
            pour vendre ses billets ou ses produits en lien avec son évènement.
        </p>

        <p>
            <u>Évènement</u> : désigne tout festival, concert, ou représentation
            scénique, musicale, sportive, d'entreprise ou autre géré par
            l'organisateur via <u>e-tickets.io</u>
        </p>

        <p>
            <u>Information</u> : désigne toute information ou donnée que le
            client ou l'organisateur communique à <u>e-tickets.io</u> en lien
            avec l'organisation d'un évènement.
        </p>

        <h3>2.10 Responsabilité</h3>
        <h3>2.10.1 Organisation des évènements</h3>
        <p>
            L'organisateur déclare et garantit à <u>e-tickets.io</u> qu'il
            dispose de tous les droits et autorisations nécessaires à
            l'organisation de l'évènement pour lequel la participation de
            <u>e-tickets.io</u> est sollicitée au titre de son offre de
            plateforme de services.
        </p>

        <p>
            L'organisateur déclare et garantit à <u>e-tickets.io</u> avoir pris
            toutes les dispositions nécessaires afin d'assurer que l'évènement
            qu'il organise est conforme à l'ensemble des lois en vigueurs sur le
            territoire, les ordonnances, le règlements, règlementations et
            obligations administratives applicables.
        </p>

        <p>
            L'organisateur garantit à <u>e-tickets.io</u> de mettre œuvre les
            mesures de lutte contre la COVID si applicable.
        </p>

        <p>
            L'évènement relevé de l'entière responsabilité de l'organisateur
            comme stipulé dans la section 2.5. Il
        </p>

        <p>
            L'organisateur décharge <u>e-tickets.io</u> de toute responsabilité
            susceptible de résulter de l'organisation d'un évènement, et
            notamment de toute responsabilité du fait des prestataires amenés à
            intervenir lors de l'organisation de l'évènement.
        </p>

        <p>
            L'organisateur s'engage à informer <u>e-tickets.io</u> dans les plus
            brefs délais de toute demande d'informations émanant d'une autorité
            administrative, de police ou judiciaire concernant l'organisation
            d'un évènement.
        </p>

        <h3>2.10.2 Documentation contractuelle de <u>e-tickets.io</u></h3>
        <p>
            L'organisateur reconnaît avoir pris connaissance des conditions
            générales de ventes, des conditions générales d'utilisation et de la
            politique de confidentialité de <u>e-tickets.io</u>, tels que
            disponibles et ponctuellement modifiées sur le site internet
            <u>e-tickets.io</u>
            (htts://<u>e-tickets.io</u>).
        </p>

        <h3>2.10.3 Informations communiquées</h3>
        <p>
            L'organisateur déclare et garantit à e-tickets que toutes les
            informations qu'il fournit (le concernant ou concernant ses clients)
            à <u>e-tickets.io</u> sont comple1tes et exactes et ne violent ni ne
            portent atteinte à aucun droit au respect de la vie privée,
            obligation de confidentialité, ou tout autre droit personnel ou
            moral d'un quelconque tiers.
        </p>

        <h2>3. Protection des données relative au Data Protection Office</h2>

        <p>
            <u>e-tickets.io</u> est enregistré en tant que responsable du
            traitement de données auprès du bureau de protection des données et
            recueille des données en toute légalité selon le Data Protection Act
            2004.
        </p>

        <h3>3.1 Définitions</h3>
        <p>
            Dans cette section, les termes : ‘nous', ‘notre' et ‘nos' font
            référence à <u>e-tickets.io</u> (E-TIX.IO LTD). ‘Vous', ‘votre' et
            ‘vos' font référence à toute personne physique majeure ou en
            capacité de contracter utilisant le site <u>e-tickets.io</u> ou y
            accédant.
        </p>

        <p>
            Dans la présente charte, les termes ‘nous', ‘notre' et ‘nos' font
            référence à la E-TIX.IO LTD. ‘Vous', ‘votre' et ‘vos' font référence
            à toute personne physique majeure ou en capacité de contracter
            utilisant le Site ou y accédant.
        </p>

        <h3>3.2 Object</h3>

        <p>
            La présente section décrit les méthodes que nous utilisons pour
            collecter, sauvegarder, traiter et utiliser vos données
            personnelles, en qualité de responsable de traitement.
        </p>
        <p>
            En nous fournissant de telles informations et en utilisant
            <u>e-tickets.io</u>, vous consentez à ce que nous collections,
            sauvegardions, traitons et utilisions vos données personnelles selon
            la législation en vigueur et les dispositions de cette section qui
            fait partie intégrantes des conditions de ventes générales.
        </p>
        <p>Quelles données collectons-nous ?</p>
        <p>Nous collectons vos données personnelles de deux manières :</p>
        <p>
            Les informations que vous vous entrées sur <u>e-tickets.io</u> :
            pour accéder à nos prestations, notamment pour passer commande via
            <u>e-tickets.io</u> ou vous inscrire, vous devez créer un compte sur
            le Site et nous fournir un certain nombre d'informations
            personnelles, à savoir : nom, prénom, adresse e-mail, numéro de
            téléphone, sexe etc.
        </p>
        <p>
            Les Informations que nous collectons automatiquement lorsque vous
            utilisez notre Site :
        </p>
        <p>
            Quel que soit votre statut, Utilisateur ou Client, nous collectons
            et exploitons diverses informations concernant votre utilisation du
            Site, telles que vos préférences de navigation, des statistiques
            concernant les pages de notre Site les plus consultées, etc.
        </p>
        <u>e-tickets.io</u>
        <p>
            <u>e-tickets.io</u> et son/ses sous-domaine peut contenir des liens
            vers d'autres sites, tels que Facebook auxquelles la cette section
            ne s'applique pas. Veuillez noter que nous ne pouvons être tenus
            pour responsable de l'activité de ces sites ou de leur contenu. Nous
            vous encourageons à prendre connaissance de la politique de
            confidentialité applicable, préalablement à toute visite d'un
            nouveau site.
        </p>
        <p>
            Pour quelles finalités et sur quel fondement légal collectons-nous
            vos données ?
        </p>
        <p>
            L'ensemble des informations recueillies sont traitées à des fins de
            gestion des fonctionnalités de <u>e-tickets.io</u> et principalement
            de la gestion des commandes de billets et l'adaptation du contenu de
            nos plateformes en fonction de vos préférences. Les données
            recueillies pourront également être utilisées à des fins d'études
            statistiques, marketing ou d'analyses.
        </p>

        <p>
            Enfin, les données recueillies sont susceptibles d'être traitées par
            <u>e-tickets.io</u> et/ou par ses partenaires organisateurs
            d'évènements. Le traitement des données des Clients est fondé sur
            l'exécution d'un contrat de vente.
        </p>
        <p>
            Le traitement des données des Utilisateurs est fondé sur le
            consentement que vous apportez à l'utilisation du Facebook pixel
            (cookie), via notre bannière de demande d'utilisation sur
            <u>e-tickets.io</u>.
        </p>
        <p>
            Le traitement peut également être fondé sur votre consentement,
            quand un tel consentement est requis (par exemple, pour
            l'utilisation de vos données à des fins de prospection commerciale
            par voie électronique).
        </p>
        <p>Avec qui partageons-nous vos données ?</p>
        <p>Les destinataires des données collectées sont :</p>
        <ul>
            <li>
                <p><u>e-tickets.io</u> (nous),</p>
            </li>
            <li>
                <p>les Organisateurs (nos partenaires commerciaux),</p>
            </li>
            <li>
                <p>
                    nos sous-traitants (tels que le prestataire d'hébergement).
                </p>
            </li>
        </ul>
        <p>
            Nous nous assurons que les destinataires des données offrent des
            garanties sérieuses de sécurité et de confidentialité des données
            personnelles transmises.
        </p>
        <p>Où sont conservés vos données ?</p>
        <p>
            Vos données sont conservées sur des serveurs aux USA, en Europe, en
            Afrique et au Moyen-Orient. Combien de temps sont conservées vos
            données ? Les données des Clients sont conservées le temps
            nécessaire à la gestion de leur traitement et afin de répondre aux
            finalités susvisées.
        </p>
        <p>
            S'agissant de l'envoi de notre newsletter, nous collectons votre
            adresse email à cette fin, sous réserve de votre désinscription qui
            peut intervenir à tout moment. Les données collectées par le biais
            des cookies sont conservées maximum 13 mois.
        </p>
        <p>Quels sont vos droits ?</p>

        <p>
            En vertu de la loi Informatique et Libertés et du GDPR, vous
            disposez, à l'égard du Traitement de vos données personnelles, d'un
            droit d'accès, d'opposition, de rectification et d'effacement.
        </p>
        <p>
            En outre, vous disposez du droit de communiquer à
            <u>e-tickets.io</u> des directives qui définissent la manière dont
            vous souhaitez que vos données personnelles soient traitées après
            votre décès (en indiquant par exemple si vous souhaitez qu'elles
            soient conservées, effacées, ou communiquées à un tiers désigné).
        </p>
        <p>
            Enfin, vous pouvez, le cas échéant, demander l'effacement des
            données personnelles qui ont été collectées lorsque vous étiez
            mineur.
        </p>
        <p>
            Par ailleurs, vous pouvez également exercer (en plus des droits
            susvisés) votre droit à la limitation du traitement et votre droit à
            la portabilité de vos données personnelles, tels que prévus par le
            GDPR.
        </p>
        <p>
            À tout moment, vous disposez du droit de retirer votre consentement
            au traitement de vos données personnelles (pour tout traitement
            fondé sur son consentement).
        </p>
        <p>
            Vous pouvez faire valoir votre droit de ne pas faire l'objet d'une
            décision fondée exclusivement sur un traitement automatisé (tel que
            le profilage par exemple) produisant des effets juridiques le
            concernant ou l'affectant de manière significative.
        </p>
        <p>
            L'exercice de ces droits se fera en transmettant la demande à
            <u>e-tickets.io</u> : Soit par e-mail à l'adresse suivante :
            <u>founder@<u>e-tickets.io</u></u>
        </p>

        <h3>3.3 Mise à jour de la section</h3>
        <p>
            Nous nous réservons le droit d'amender la section afin de la mettre
            en conformité avec l'avancée de notre technologie, les meilleurs
            pratiques professionnelles en la matière ou les évolutions de la
            législation. Au cas ou ces changements seraient substantiels, nous
            porterions à votre connaissance et, au cas où cela serait requis par
            la loi, vous solliciterons afin d'obtenir à nouveau votre
            consentement.
        </p>
        <p>
            Cette politique de confidentialité a été mise à jour en mars 2023.
            Informations légales :
        </p>
        <p>E-TIX LTD - (<u>e-tickets.io</u>) | BRN : C23194251</p>
        <p>Old Post Office Road, The Hive, Moka 1808-14</p>
        <a href="https://www.<u>e-tickets.io</u>"
            >https://www.<u>e-tickets.io</u></a
        >
        <br /><br />
        <a href="mailto:founder@<u>e-tickets.io</u>"
            >founder@<u>e-tickets.io</u></a
        >
    </div>
</template>

<style>
#terms-container {
    margin: 80px auto;
}

#terms-container h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;

    /* identical to box height */
    letter-spacing: 0.1em;

    color: #000000;
    margin-top: 40px;
}

#terms-container > h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;

    /* identical to box height */
    letter-spacing: 0.1em;

    color: #000000;
    margin-top: 40px;
}

#terms-container > p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.1em;

    color: #000000;
}
</style>
