<template>
    <div class="get-ticket-title">
        <h1>Order Confirmed</h1>
        <p>
            Your order is confirmed and your event pass will be sent to you by
            email shortly. Your pass will include a QR code that will be scanned
            at the event entrance for admission. Please have it available on
            your phone for entry.
        </p>
    </div>

    <div class="container box-drop">
        <div class="receipt-method-panel">
            <div class="receipt-review responsive-purchaser-receipt">
                <h4>Purchaser Email</h4>
                <h5 class="responsive-divider-receipt">-</h5>
                <h3>{{ attendee.email }}</h3>
                <div class="responsive-seperator-receipt"></div>
                <h4>Processed on</h4>
                <h5 class="responsive-divider-receipt">-</h5>
                <h3>{{ formattedDate }}</h3>
            </div>

            <div class="payment-method-seperator"></div>

            <div class="receipt-review">
                <h4>Ticket Type</h4>
                <h5>-</h5>
                <h3>{{ ticket.title }}</h3>
                <h4>Ticket Quantity</h4>
                <h5>-</h5>
                <h3>{{ receipt.ticketQuantity }}</h3>
                <h4>Total</h4>
                <h5>-</h5>
                <h3>${{ receipt.totalPrice }}</h3>
            </div>
        </div>
        <div class="receipt-note">
            <p>
                Note* Passes will be automatically sent to respective attendees
                email address.
            </p>
        </div>
    </div>

    <div class="get-ticket-title">
        <a href="/">
            <button class="goback-button">HOMEPAGE</button>
        </a>
    </div>
</template>

<script>
// import { RouterLink } from "vue-router";

export default {
    name: "confirmation-view",
    data() {
        return {
            receipt: {},
            attendee: {},
            ticket: {},
        };
    },
    computed: {
        formattedDate() {
            const dateObj = new Date(this.receipt.date);
            return dateObj.toLocaleString();
        },
    },
    mounted() {
        fetch(this.$apiUrls.confirmation, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                this.receipt = data.receipt_data;
                this.attendee = data.attendee_data;
                this.ticket = data.ticket_data;
            })
            .catch((error) => console.log(error.message));
    },
};
</script>
