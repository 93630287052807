<template>
    <main class="body-wrapper">
        <!-- ---------------------------- Header Logos ----------------------------- -->
        <header class="container">
            <div id="logo-sub">
                <a href="https://turbine.e-tickets.io">
                    <img
                        src="./assets/images/event-images/turbine_logo.png"
                        alt="company"
                    />
                </a>
            </div>
            <div id="logo-main">
                <a href="https://e-tickets.io">
                    <img
                        src="./assets/images/E-tickets-Logo-single.png"
                        alt="e-tickets.io"
                    />
                </a>
            </div>
        </header>

        <div class="content-wrapper">
            <RouterView />
        </div>

        <!-- ---------------------------- Footer Logos ----------------------------- -->
        <br /><br /><br /><br /><br />
        <footer class="container">
            <div class="footer-border"></div>
            <div class="footer-grid">
                <div class="footer-left">
                    <div class="footer-logo">
                        <img
                            src="./assets/images/E-tickets-Logo-single.png"
                            alt="e-tickets.io"
                        />
                        <p>seamless ticketing experience</p>
                    </div>

                    <div class="icons-buttons">
                        <a
                            href="https://www.facebook.com/profile.php?id=100089321370776&mibextid=LQQJ4d"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="./assets/images/facebook.png"
                                alt="facebook"
                            />
                        </a>
                        <a
                            href="https://instagram.com/etickets.io?igshid=YmMyMTA2M2Y="
                        >
                            <img
                                src="./assets/images/instagram.png"
                                alt="instagram"
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/e-tickets-io">
                            <img
                                src="./assets/images/linkedin.png"
                                alt="linkedin"
                            />
                        </a>
                    </div>
                </div>

                <div class="footer-right">
                    <h4>Product Help</h4>
                    <p>
                        <a href="https://wa.me/23057699807">Contact Support</a>
                    </p>
                    <p>
                        <RouterLink
                            :to="$router.resolve('/terms-and-conditions').href"
                        >
                            Terms and Conditions
                        </RouterLink>
                    </p>
                </div>
            </div>

            <p style="text-align: center">
                © Copyright 2024 E-tickets.io. All rights reserved.
            </p>
        </footer>
        <br /><br /><br /><br /><br />
    </main>
</template>

<script>
// import { RouterView, RouterLink } from "vue-router";

export default {};
</script>

<style>
#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.body-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-wrapper {
    flex: 1;
}

footer {
    margin-top: auto;
}
</style>
