<template>
    <div class="attendee-details container box-drop">
        <div class="box-heading" ref="component">
            <div class="circle-step">
                <h1>{{ attendeeNumber + 3 }}</h1>
            </div>
            <div class="title-texts">
                <h1>Attendee {{ attendeeNumber + 2 }} - Information</h1>
                <h2 v-if="stepNumber == attendeeNumber">
                    Please note that the information below will be displayed on
                    your pass.
                </h2>
            </div>
        </div>

        <div v-if="stepNumber == attendeeNumber" class="attendee-container">
            <div class="attendee-inputs">
                <label
                    class="label-tag span-two"
                    style="display: flex; align-items: center"
                >
                    <h4 style="font-size: 18px">Title:</h4>
                    <label
                        class="title-radio-label"
                        v-for="(titleOption, index) in titleOptions"
                        :key="index"
                    >
                        <input
                            type="radio"
                            v-model="attendeeData.username.gender"
                            :value="titleOption"
                            class="title-radio-input"
                        />
                        {{ titleOption }}
                    </label>
                    <h4
                        style="color: red"
                        class="input-error-text"
                        v-if="v$.attendeeData.username.gender.$error"
                    >
                        *Required
                    </h4>
                </label>

                <label class="label-tag">
                    <h4>
                        First Name*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.username.firstname.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.username.firstname"
                        :class="{
                            'input-error':
                                v$.attendeeData.username.firstname.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="First Name*"
                    />
                </label>

                <label class="label-tag">
                    <h4>
                        Last Name*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.username.lastname.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.username.lastname"
                        :class="{
                            'input-error':
                                v$.attendeeData.username.lastname.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Last Name*"
                    />
                </label>

                <label class="label-tag span-two">
                    <h4>
                        Email*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.email.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.email"
                        :class="{
                            'input-error': v$.attendeeData.email.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Email*"
                    />
                </label>

                <label class="label-tag">
                    <h4>
                        Company*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.company.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.company"
                        :class="{
                            'input-error': v$.attendeeData.company.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Company"
                    />
                </label>

                <label class="label-tag">
                    <h4>
                        Job Title*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.jobTitle.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.jobTitle"
                        :class="{
                            'input-error': v$.attendeeData.jobTitle.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Job Title"
                    />
                </label>

                <!-- Country -->
                <label class="label-tag">
                    <h4>Country</h4>
                    <select v-model="attendeeData.country" class="select-input">
                        <option
                            v-for="(countryOption, index) in countryOptions"
                            :key="index"
                            :value="countryOption"
                        >
                            {{ countryOption }}
                        </option>
                    </select>
                </label>
                <label class="label-tag">
                    <h4>
                        Phone Number*
                        <span
                            class="input-error-text"
                            v-if="v$.attendeeData.phone.$error"
                            >Required</span
                        >
                    </h4>
                    <input
                        v-model="attendeeData.phone"
                        :class="{
                            'input-error': v$.attendeeData.phone.$error,
                        }"
                        class="text-input"
                        type="text"
                        placeholder="Phone Number*"
                    />
                </label>
            </div>

            <div class="card-preview">
                <!-- <h1>{{ ticketTitle }}</h1> -->
                <h1>{{ ticketAccess }}</h1>
                <h2>
                    {{ attendeeData.username.firstname || "Firstname" }}
                    {{ attendeeData.username.lastname || "Lastname" }}
                </h2>
                <h3>{{ attendeeData.jobTitle || "Job Title" }}</h3>
                <h4>{{ attendeeData.company || "Company" }}</h4>

                <div class="card-preview-images">
                    <img
                        v-if="attendee_image_url"
                        class="card-preview-img"
                        :src="attendee_image_url"
                        alt="profile-photo"
                    />
                    <img
                        v-if="!attendee_image_url"
                        class="card-preview-img"
                        src="../assets/images/photo-profile.png"
                        alt="profile-photo"
                    />
                    <img
                        class="card-preview-img"
                        src="../assets/images/qr-code.svg"
                        alt="qr-code"
                    />
                </div>

                <img
                    class="card-preview-logo"
                    src="../assets/images/event-images/turbine_logo.png"
                    alt="company-logo"
                />
            </div>
        </div>

        <div v-if="stepNumber == attendeeNumber" class="attendee-buttons">
            <div class="left-attendee-buttons">
                <input
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    accept="image/*"
                    @change="handleSelectedPhoto"
                />
                <button @click="openFileInput" class="photo-button">
                    <img
                        src="../assets/images/upload-photo-ico.svg"
                        alt="upload-photo"
                    />
                    <div>
                        Upload Photo
                        <h4>(for the badge)</h4>
                    </div>
                </button>
            </div>
            <div class="right-attendee-buttons">
                <button
                    class="previous-button"
                    @click="previousStep(attendeeNumber)"
                >
                    Previous
                </button>
                <button class="next-button" @click="validateData">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
    name: "AttendeesForms",
    props: {
        attendeeNumber: { type: Number, required: true },
        stepNumber: { type: Number, required: true },
        ticketTitle: { type: String, required: true },
        ticketAccess: { type: String, required: true },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            attendeeData: {
                username: {
                    gender: null,
                    firstname: "",
                    lastname: "",
                },
                company: "",
                jobTitle: "",
                email: "",
                phone: "",
                photo: "",
                country: "Mauritius",
            },
            attendee_image_url: null,
            titleOptions: ["Mr", "Mrs", "Other"],
            countryOptions: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "Andorra",
                "Angola",
                "Antigua & Deps",
                "Argentina",
                "Armenia",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bhutan",
                "Bolivia",
                "Bosnia Herzegovina",
                "Botswana",
                "Brazil",
                "Brunei",
                "Bulgaria",
                "Burkina",
                "Burundi",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cape Verde",
                "Central African Rep",
                "Chad",
                "Chile",
                "China",
                "Colombia",
                "Comoros",
                "Congo",
                "Congo {Democratic Rep}",
                "Costa Rica",
                "Croatia",
                "Cuba",
                "Cyprus",
                "Czech Republic",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "East Timor",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Ethiopia",
                "Fiji",
                "Finland",
                "France",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Greece",
                "Grenada",
                "Guatemala",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Honduras",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland {Republic}",
                "Israel",
                "Italy",
                "Ivory Coast",
                "Jamaica",
                "Japan",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Korea North",
                "Korea South",
                "Kosovo",
                "Kuwait",
                "Kyrgyzstan",
                "Laos",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macedonia",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Mauritania",
                "Mauritius",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Morocco",
                "Mozambique",
                "Myanmar, {Burma}",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Zealand",
                "Nicaragua",
                "Niger",
                "Nigeria",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Poland",
                "Portugal",
                "Qatar",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "St Kitts & Nevis",
                "St Lucia",
                "Saint Vincent & the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome & Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan",
                "Suriname",
                "Swaziland",
                "Sweden",
                "Switzerland",
                "Syria",
                "Taiwan",
                "Tajikistan",
                "Tanzania",
                "Thailand",
                "Togo",
                "Tonga",
                "Trinidad & Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Vatican City",
                "Venezuela",
                "Vietnam",
                "Yemen",
                "Zambia",
                "Zimbabwe",
            ],
        };
    },
    validations() {
        return {
            attendeeData: {
                username: {
                    gender: { required },
                    firstname: { required },
                    lastname: { required },
                },
                company: { required },
                jobTitle: { required },
                email: { required, email },
                phone: { required },
            },
        };
    },
    methods: {
        validateData() {
            this.v$.attendeeData.$touch();
            if (!this.v$.attendeeData.$error) {
                this.$emit("attendee-submitted", this.attendeeData);
                this.$refs.component.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }
        },
        previousStep(currentStepNumber) {
            const previousNumber = currentStepNumber - 1;
            this.$emit("previous-step", previousNumber);
            this.$refs.component.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        handleSelectedPhoto(event) {
            const file = event.target.files[0];

            const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
            if (!allowedTypes.includes(file.type) || file.size > 5242880) {
                alert(
                    "Please select a valid image file (JPEG, PNG, or GIF) and make sure that your photo size is less than 5mb."
                );
                this.attendeeData.photo = null;
                this.attendee_image_url = null;
                this.$refs.fileInput.value = "";
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const mediaType = file.type;
                const base64Data = reader.result.split(",")[1];
                const base64Image = `data:${mediaType};base64,${base64Data}`;
                this.attendeeData.photo = base64Image;
                this.attendee_image_url = URL.createObjectURL(file);
            };
        },
    },
};
</script>
