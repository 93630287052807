import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import BookingView from "../views/BookingView.vue";
import PaymentPortalView from "../views/PaymentPortalView.vue";
import ConfirmationView from "../views/ConfirmationView";
import TermsAndConditionsView from "../views/TermsAndConditions.vue";
import Dashboard from "../views/Dashboard.vue";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/booking/:id",
            name: "booking",
            component: BookingView,
        },
        {
            path: "/payment",
            name: "payment",
            component: PaymentPortalView,
        },
        {
            path: "/confirmation",
            name: "confirmation",
            component: ConfirmationView,
        },
        {
            path: "/terms-and-conditions",
            name: "terms-and-conditions",
            component: TermsAndConditionsView,
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard,
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo({
        top: 0,
        behavior: "instant",
    });
    next();
});

export default router;
